import { Badge, Box, Button, Card, CardBody, CardFooter, Divider, Flex, HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { EditIcon, WarningTwoIcon } from "@chakra-ui/icons";
import React, { memo } from "react";
import { Game } from "../../../../api/games/game.model";
import { GameCompletionStatuses } from "../GameCompletionStatuses/GameCompletionStatuses";

type Props = {
  game: Game;
};

export const GameCardItem = memo((props: Props) => {
  const shouldShowCrossplayVerificationWarning =
    !props.game.crossplayVerification.length && !!props.game.crosses.length;

  return (
    <Card>
      <CardBody>
        <Flex justify={"space-between"}>
          <Text fontWeight="medium">{props.game.name}</Text>
          <Box>
            <Badge colorScheme={props.game.publishStatus === "Published" ? "green" : "gray"}>
              {props.game.publishStatus}
            </Badge>
          </Box>
        </Flex>
        <Divider my={3} />
        <GameCompletionStatuses game={props.game} />
        {shouldShowCrossplayVerificationWarning && (
          <Flex alignItems={"center"} columnGap={2} mt={5}>
            <WarningTwoIcon color={"red.500"} />
            <Box color={"red.500"} fontWeight={"500"}>
              Game does not have verified crossplay
            </Box>
          </Flex>
        )}
      </CardBody>
      <CardFooter>
        <HStack spacing={2}>
          <Link as={RouterLink} to={`/games/${props.game._id}`}>
            <Button leftIcon={<EditIcon />} variant="outline">
              Edit
            </Button>
          </Link>
        </HStack>
      </CardFooter>
    </Card>
  );
});
