import {
  Button,
  Center,
  HStack,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  useBoolean,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useGame } from "./hooks/useGame";
import { usePlatforms } from "../../hooks/usePlatforms";
import { useStores } from "../../hooks/useStores";
import { GameBasicInfo } from "./components/GameBasicInfo";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { GameSeo } from "./components/GameSeo";
import { GameCrosses } from "./components/GameCrosses/GameCrosses";
import { GameBuyLinks } from "./components/GameBuyLinks";
import { GameImages } from "./components/GameImages/GameImages";
import { Game } from "../../api/games/game.model";
import { gameService } from "../../api/games/game.service";
import React, { useEffect, useState } from "react";
import { GameUrlTabNames } from "./game-url-tab-names";
import { useNavigate, useParams } from "react-router-dom";
import { usePreview } from "../../hooks/usePreview/usePreview";
import { DeleteIcon, LinkIcon, RepeatIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { GameGalleryTab } from "./components/GameGalleryTab/GameGalleryTab";
import { GenericConfirmModal } from "../../components/GenericConfirmModal/GenericConfirmModal";

const tabNameByIndex = (index: number) => {
  switch (index) {
    case 0:
      return GameUrlTabNames.BasicInfo;
    case 1:
      return GameUrlTabNames.SEO;
    case 2:
      return GameUrlTabNames.BuyLinks;
    case 3:
      return GameUrlTabNames.Crosses;
    case 4:
      return GameUrlTabNames.Image;
    case 5:
      return GameUrlTabNames.Gallery;
    default:
      return GameUrlTabNames.BasicInfo;
  }
};

const indexByTabName = (tabName: GameUrlTabNames | string | undefined) => {
  switch (tabName) {
    case GameUrlTabNames.BasicInfo:
      return 0;
    case GameUrlTabNames.SEO:
      return 1;
    case GameUrlTabNames.BuyLinks:
      return 2;
    case GameUrlTabNames.Crosses:
      return 3;
    case GameUrlTabNames.Image:
      return 4;
    case GameUrlTabNames.Gallery:
      return 5;
    default:
      return 0;
  }
};

export const GamePage = () => {
  const toast = useToast();

  const navigate = useNavigate();
  const { tab, gameId } = useParams();

  const gameData = useGame();
  const platformsData = usePlatforms();
  const storesData = useStores();

  const [previewEntity, setPreviewEntity] = useState<Game | null>(null);
  const preview = usePreview();

  const [currentTabIndex, setCurrentTabIndex] = useState(indexByTabName(tab));

  const [publishStatusLoading, updatePublishStatusLoading] = useBoolean(false);

  const removeModalDisclosure = useDisclosure();

  const [removeLoading, setRemoveLoading] = useState(false);

  const remove = async () => {
    if (gameId === undefined) {
      return;
    }

    setRemoveLoading(true);

    try {
      await gameService.remove(gameId);
      toast({
        title: "Game has been removed",
        status: "success",
      });
      navigate("/games", {
        replace: true,
      });
    } catch (err) {
      toast({
        title: "Game has not been removed",
        status: "error",
      });
      console.log(err);
    }

    setRemoveLoading(false);
  };

  const updatePreviewEntity = (fn: (entity: Game) => Game) => {
    if (!gameData.entity) {
      return;
    }

    const nextEntity = fn(previewEntity || gameData.entity);

    preview.updateEntity(nextEntity);
    setPreviewEntity(nextEntity);
  };

  useEffect(() => {
    if (!gameData.entity) {
      return;
    }

    setPreviewEntity(gameData.entity);
    preview.updateEntity(gameData.entity);
  }, [gameData.entity]);

  const updatePublishStatus = async (publishStatus: Game["publishStatus"]) => {
    if (!gameData.entity) {
      return;
    }

    updatePublishStatusLoading.on();
    try {
      await gameService.updatePublishStatus(gameData.entity._id, publishStatus);

      toast({ title: `Game status was successfully changed to ${publishStatus}`, status: "success" });

      await gameData
        .refetch()
        .catch(() =>
          toast({
            title: "Could not refetch Game, please reload a page",
            status: "warning",
          })
        )
        .then(() => updatePublishStatusLoading.off());
    } catch (err) {
      toast({ title: `Game status was not changed to ${publishStatus}`, status: "error" });
      console.log(err);
    }
  };

  if (!gameData.hasBeenLoaded || !platformsData.hasBeenLoaded || !storesData.hasBeenLoaded) {
    return (
      <Center my={3}>
        <Spinner />
      </Center>
    );
  }

  if (!gameData.entity) {
    return <Center>Error</Center>;
  }

  return (
    <LoaderWrapper
      noData={gameData.hasBeenLoaded && !gameData.entity}
      initialLoading={gameData.loading && !gameData.hasBeenLoaded}
      loading={gameData.loading}
    >
      <VStack alignItems={"flex-start"} mb={4}>
        <Tag size={"md"} mb={2}>
          {gameData.entity.name}
        </Tag>
        <HStack spacing={1}>
          <a href={`${process.env.REACT_APP_COPILOT_ORIGIN}/games/${gameData.entity.slug}`} target="_blank">
            <Button size={"sm"} leftIcon={<LinkIcon />}>
              Open in Copilot
            </Button>
          </a>
          <Button
            size={"sm"}
            colorScheme={"teal"}
            onClick={preview.start}
            isLoading={preview.connectionStatus.Connecting}
            disabled={!preview.connectionStatus.Initial}
          >
            {preview.connectionStatus.Initial && "Open preview"}
            {preview.connectionStatus.Connecting && "Opening"}
            {preview.connectionStatus.Connected && "Preview is Open"}
            {preview.connectionStatus.Disconnected && "Preview has been disconnected"}
          </Button>
          {preview.connectionStatus.Disconnected && (
            <Button size={"sm"} onClick={preview.start}>
              <RepeatIcon />
            </Button>
          )}
          {preview.connectionStatus.Connected && (
            <Button size={"sm"} onClick={preview.disconnect} leftIcon={<SmallCloseIcon />}>
              Disconnect
            </Button>
          )}
          {preview.connectionStatus.Connecting && (
            <Button size={"sm"} onClick={preview.disconnect} leftIcon={<SmallCloseIcon />}>
              Stop connecting
            </Button>
          )}
        </HStack>
      </VStack>
      <Tabs
        index={currentTabIndex}
        onChange={(index) => {
          setCurrentTabIndex(index);
          navigate(`/games/${gameId}/${tabNameByIndex(index)}`);
        }}
      >
        <TabList>
          <Tab>Basic Info</Tab>
          <Tab>SEO</Tab>
          <Tab>Buy Link</Tab>
          <Tab>Crosses</Tab>
          <Tab>Images</Tab>
          <Tab>Gallery</Tab>
          <Button
            ml={"auto"}
            mr={1}
            leftIcon={<DeleteIcon />}
            colorScheme="red"
            isLoading={removeLoading}
            onClick={removeModalDisclosure.onOpen}
          >
            Remove
          </Button>
          <Button
            variant={"outline"}
            mb={1}
            colorScheme={"green"}
            onClick={() => updatePublishStatus(gameData.entity?.publishStatus === "Published" ? "Draft" : "Published")}
            isLoading={publishStatusLoading}
          >
            {gameData.entity.publishStatus === "Published" ? "Back To Draft" : "Publish Game"}
          </Button>
        </TabList>

        <TabPanels>
          <TabPanel>
            <GameBasicInfo
              game={gameData.entity}
              onSaveSuccessfully={gameData.refetch}
              updateMemoryGame={updatePreviewEntity}
            />
          </TabPanel>
          <TabPanel>
            <GameSeo
              game={gameData.entity}
              onSaveSuccessfully={gameData.refetch}
              updateMemoryGame={updatePreviewEntity}
            />
          </TabPanel>
          <TabPanel>
            <GameBuyLinks
              game={gameData.entity}
              platforms={platformsData.list}
              stores={storesData.list}
              onSaveSuccessfully={gameData.refetch}
              onRefresh={gameData.refetch}
            />
          </TabPanel>
          <TabPanel>
            <GameCrosses
              game={gameData.entity}
              platforms={platformsData.list}
              stores={storesData.list}
              onSaveSuccessfully={gameData.refetch}
              updateMemoryGame={updatePreviewEntity}
            />
          </TabPanel>
          <TabPanel>
            <GameImages
              game={gameData.entity}
              onSaveSuccessfully={gameData.refetch}
              updateMemoryGame={updatePreviewEntity}
            />
          </TabPanel>
          <TabPanel>
            <GameGalleryTab
              game={gameData.entity}
              refetchGame={gameData.refetch}
              updateMemoryGame={updatePreviewEntity}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <GenericConfirmModal
        title={"Are you sure you want to remove game?"}
        isOpen={removeModalDisclosure.isOpen}
        onClose={removeModalDisclosure.onClose}
        actionText={"Remove"}
        actionLoading={removeLoading}
        onAction={remove}
        type={"remove"}
      />
    </LoaderWrapper>
  );
};
