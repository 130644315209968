import { useState } from "react";
import { v4 } from "uuid";
import { uploaderService } from "../../api/uploader/uploader.service";
import { util_arr_updateObj } from "../../utils/array/util_arr_update-obj";
import { util_arr_remove_obj } from "../../utils/array/util_arr_remove_obj";
import { util_arr_insertElem } from "../../utils/array/util_arr_insert-elem";
import { util_arr_findObjInArr } from "../../utils/array/util_arr_find-obj-in-arr";
import { util_arr_changeElemPosition } from "../../utils/array/util_arr_change-elem-position";
import { imageInputToLocal } from "./utils/image-input-to-local";
import { fileToDataUrl } from "../../utils/file/file-to-data-url";

export type LocalImage = {
  uniqId: string;
  original: ImageInput;
  current: {
    key: string | null;
    gradient: string | null;
    file: null | File;
  };
};

type Options = {
  images?: ImageInput[];
};

export type ImageInput = {
  uniqId?: string;
  key?: string | null;
  gradient?: string | null;
};

export type ImageOutput = {
  _id?: string;
  key: string;
  gradient: string;
};

export type ImageUpdater = {
  updateKey: (uniqId: string, key: string) => void;
  updateGradient: (uniqId: string, gradient: string) => void;
  updateFile: (uniqId: string, file: File) => void;
};

type Output = {
  images: LocalImage[];

  updater: ImageUpdater;

  resetImages: (images?: ImageInput[]) => void;
  addImage: (position?: number) => void;
  removeImage: (uniqId: string) => void;
  changePosition: (uniqId: string, nextPosition: number) => void;
  getImageResults: (options: { srcResultType: "final" | "dataUrl" }) => Promise<ImageOutput[]>;
};

export const useImages = (input?: Options): Output => {
  const [images, setImages] = useState<LocalImage[]>(imageInputToLocal(input?.images || []));

  const resetImages: Output["resetImages"] = (images) => {
    if (!images?.length) {
      setImages([]);
      return;
    }

    setImages(imageInputToLocal(images));
  };

  const updateKey: ImageUpdater["updateKey"] = (uniqId, key) => {
    setImages((prev) =>
      util_arr_updateObj(prev, "uniqId", uniqId, (image) => ({
        ...image,
        current: {
          ...image.current,
          key,
        },
      }))
    );
  };

  const updateGradient: ImageUpdater["updateGradient"] = (uniqId, gradient) => {
    setImages((prev) =>
      util_arr_updateObj(prev, "uniqId", uniqId, (image) => ({
        ...image,
        current: {
          ...image.current,
          gradient,
        },
      }))
    );
  };

  const updateFile: ImageUpdater["updateFile"] = (uniqId, file) => {
    setImages((prev) =>
      util_arr_updateObj(prev, "uniqId", uniqId, (image) => ({
        ...image,
        current: {
          ...image.current,
          file,
        },
      }))
    );
  };

  const addImage: Output["addImage"] = (position) => {
    const uniqId = v4();

    const image: LocalImage = {
      uniqId,
      original: {},
      current: {
        key: null,
        gradient: null,
        file: null,
      },
    };

    setImages((prev) => util_arr_insertElem(prev, image, position));
  };

  const removeImage: Output["removeImage"] = (uniqId: string) => {
    setImages((prev) => util_arr_remove_obj(prev, "uniqId", uniqId));
  };

  const changePosition: Output["changePosition"] = (uniqId, nextPosition) => {
    const imageInElemFindRes = util_arr_findObjInArr(images, "uniqId", uniqId);

    if (!imageInElemFindRes?.element) {
      return;
    }

    setImages((prev) => util_arr_changeElemPosition(prev, imageInElemFindRes.index, nextPosition));
  };

  const getImageResults: Output["getImageResults"] = async (options) => {
    const promises = images.map<Promise<ImageOutput>>((image) => {
      return new Promise((resolve, reject) => {
        // If image config contains file, it means that neither image was created or updated
        if (image.current.file) {
          // In any case image cannot exist without gradient
          if (!image.current.gradient) {
            reject(new Error("Cannot create image without gradient"));
            return;
          }

          if (options.srcResultType === "final") {
            const formData = new FormData();
            formData.append("file", image.current.file);

            uploaderService
              .upload(formData, { convertToWebp: true })
              .then((res) => {
                if (!image.current.gradient) {
                  reject(new Error("Cannot create image without gradient"));
                  return;
                }

                const finalObj: ImageOutput = {
                  _id: image.original.uniqId,
                  key: res.data.fileName,
                  gradient: image.current.gradient,
                };

                if (!image.original.uniqId) {
                  delete finalObj._id;
                }

                resolve(finalObj);
              })
              .catch(reject);
          } else if (options.srcResultType === "dataUrl") {
            fileToDataUrl(image.current.file).then((imageDataURL) => {
              if (!image.current.gradient) {
                reject(new Error("Cannot create image without gradient"));
                return;
              }

              if (imageDataURL === null) {
                reject(new Error("Cannot convert file to Data URL"));
                return;
              }

              const finalObj: ImageOutput = {
                _id: image.original.uniqId,
                key: imageDataURL,
                gradient: image.current.gradient,
              };

              if (!image.original.uniqId) {
                delete finalObj._id;
              }

              resolve(finalObj);
            });
          }

          return;
        }

        // In this case it means that this image existed before, and we do not need to touch anything

        if (!image.original.uniqId || !image.original.key || !image.original.gradient) {
          reject(new Error("You cannot upload images without image"));
          return;
        }

        resolve({
          _id: image.uniqId,
          key: image.original.key,
          gradient: image.original.gradient,
        });
      });
    });

    const imagesOutput = await Promise.all(promises);

    return imagesOutput;
  };

  return {
    images,
    updater: {
      updateKey,
      updateGradient,
      updateFile,
    },
    resetImages: resetImages,
    addImage: addImage,
    removeImage: removeImage,
    changePosition: changePosition,
    getImageResults: getImageResults,
  };
};
