import { Box, Button, Flex, HStack, useToast, VStack } from "@chakra-ui/react";
import { genName } from "../../../../utils/components/genName";
import { Game, GameImage } from "../../../../api/games/game.model";
import { Image } from "../../../../components/Image/Image";
import { useImages } from "../../../../components/Image/useImages";
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { gameService } from "../../../../api/games/game.service";

type Props = {
  game: Game;
  refetchGame: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameGalleryTab = (props: Props) => {
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const galleryImages = useImages();

  useEffect(() => {
    if (!props.game.mediaGallery.length) {
      galleryImages.resetImages([{}]);
      return;
    }

    galleryImages.resetImages(
      props.game.mediaGallery.map((image) => ({ key: image.key, uniqId: image._id, gradient: image.gradient }))
    );
  }, [props.game]);

  useEffect(() => {
    (async () => {
      const localImagesOutput = await galleryImages.getImageResults({ srcResultType: "dataUrl" });

      // TODO @feature [media-gallery] @description we should give an ability to pass option keep ids, and it should be well typed so no additional manipulation required
      props.updateMemoryGame((prev) => ({
        ...prev,
        mediaGallery: localImagesOutput.map((image, index) => ({
          ...image,
          _id: image._id || `${index}`,
        })),
      }));
    })();
  }, [galleryImages.images]);

  const update = async () => {
    setLoading(true);

    try {
      const imagesOutput = await galleryImages.getImageResults({ srcResultType: "final" });

      await gameService.updateMediaGallery(props.game._id, imagesOutput);

      await props.refetchGame();

      toast({
        title: "Game gallery was saved",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "Something went wrong while saving galery, please reload a page",
        status: "error",
      });
    }

    setLoading(false);
  };

  return (
    <Box {...genName(GameGalleryTab)}>
      <Box>
        <VStack spacing={2} alignItems={"stretch"}>
          {galleryImages.images.map((image, index) => (
            <Box
              position={"relative"}
              key={image.uniqId}
              borderWidth={"1px"}
              borderColor={"gray.200"}
              py={2}
              px={1}
              borderRadius={"10px"}
            >
              <HStack spacing={2} position={"absolute"} top={"10px"} right={"10px"}>
                <Button
                  size={"xs"}
                  disabled={index === 0}
                  onClick={() => galleryImages.changePosition(image.uniqId, index - 1)}
                >
                  <ArrowUpIcon />
                </Button>
                <Button
                  disabled={index === galleryImages.images.length - 1}
                  size={"xs"}
                  onClick={() => galleryImages.changePosition(image.uniqId, index + 1)}
                >
                  <ArrowDownIcon />
                </Button>
                <Button size={"xs"} colorScheme={"red"} onClick={() => galleryImages.removeImage(image.uniqId)}>
                  <DeleteIcon />
                </Button>
              </HStack>
              <Box
                lineHeight={0}
                mb={2}
                onClick={() => {
                  galleryImages.addImage(index);
                }}
              >
                <Button size={"xs"}>Add before</Button>
              </Box>
              <Image ratio={{ size: 20, x: 16, y: 9 }} config={image} updater={galleryImages.updater} />
              <Box lineHeight={0} mt={2}>
                <Button
                  size={"xs"}
                  onClick={() => {
                    galleryImages.addImage(index + 1);
                  }}
                >
                  Add after
                </Button>
              </Box>
            </Box>
          ))}
        </VStack>
      </Box>
      <Flex mt={2} justifyContent={"flex-end"}>
        <Button variant={"outline"} colorScheme={"green"} isLoading={loading} onClick={update}>
          Save
        </Button>
      </Flex>
    </Box>
  );
};
